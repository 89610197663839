import { gql, useQuery } from '@apollo/client'
import { initializeApollo } from 'utils/apollo'
import { HomepageTemplate } from 'components/template'
import { normalizeGraphQLResults } from 'utils/safe'
import { HOME_CLIENT, HOME_SERVER } from 'utils/queries'
import { useEffect, useState } from 'react'
import { NextWrapper } from '../data-fetching-wrapper'

function Home({ pagina, serverData }) {
  const { data } = useQuery(HOME_CLIENT, { fetchPolicy: 'cache-first' })

  const [clientData, setClientData] = useState({
    boxMarketing2: null,
    boxMarketingProdotti: null,
    boxQuote: null,
    articoliMagazine: [],
    prodotti: [],
  })

  useEffect(() => {
    if (data) handleSetCLientData(data)
  }, [data])

  function handleSetCLientData(data) {
    const normalized = {
      prodotti: normalizeGraphQLResults(data.prodotti),
      articoliMagazine: normalizeGraphQLResults(data.articoliMagazine),
      boxQuote: normalizeGraphQLResults(data.boxQuote),
      boxMarketing2: normalizeGraphQLResults(data.boxMarketing2),
      boxMarketingProdotti: normalizeGraphQLResults(data.boxMarketingProdotti),
    }

    setClientData(normalized)
  }

  return <HomepageTemplate pagina={pagina} serverData={serverData} clientData={clientData} />
}

export const getStaticProps = NextWrapper.getStaticProps(async () => {
  const client = initializeApollo()

  const {
    data: { boxHero, linee },
  } = await client.query({
    query: HOME_SERVER,
  })

  const serverData = {
    hero: normalizeGraphQLResults(boxHero),
    linee: normalizeGraphQLResults(linee),
  }

  const {
    data: { pagina },
  } = await client.query({
    query: gql`
      query {
        pagina(chiave: "homepage") {
          breadcrumbs {
            titolo
            url
          }
          seo {
            title
            description
            image
          }
          chiave
        }
      }
    `,
  })

  return {
    props: { serverData, pagina },
    revalidate: 60 * 5,
  }
})

Home.withPreHeader = true

export default Home
